import React, { useContext, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate, useParams } from "react-router-dom";
import TmdbContext from "../context/TmdbContext";
import AuthContext from "../context/User/AuthContext";
import styled, { css } from "styled-components";
import ReactShowMoreText from "react-show-more-text";
import FavWishAdd from "../components/FavWishAdd/FavWishAdd";
import CategoriesWrapper from "../components/CardWrapper/CategoriesWrapper";
import MovieTrailers from "../components/MovieTrailer/MovieTrailers";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import LoadingCircle from "../components/Loading/LoadingCircle";
import ShareComponent from "../components/ShareComponent/ShareComponent";
import MasterCardWrapper from "../components/CardWrapper/MasterCardWrapper";
import { ReviewsContext } from "../context/Reviews/ReviewsContext";
import ReviewsSection from "../components/Reviews/ReviewsSection";

const DetailsPage = () => {
  const {
    getDetails,
    credits,
    creditsSeries,
    cast,
    provider,
    reviews,
    dirID,
    social,
    rekon,
  } = useContext(TmdbContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState(undefined);
  const [reviewExist, setReviewExist] = useState(false);
  const [details, setDetails] = useState("");
  const [country, setCountry] = useState("GB");
  const [watched, setWatched] = useState(false);
  const [ratingScore, setRatingScore] = useState(null);
  const navigate = useNavigate();
  const { id, type } = useParams();
  const shareUrl = `https://myhubcinema.com/detailspage/${type}/${id}`; // The URL you want to share
  const shareTitle = `Check out this ${type === "tv" ? "show" : "movie"}`; // The title or description of the shared content
  const { checkIfReviewExists, myreviews } = useContext(ReviewsContext);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const navigateToActorDetails = (crewId) => {
    navigate(`/actordetails/${crewId}`);
  };

  const navigateToSeasonsDetails = (id) => {
    navigate(
      `/seasonsepisodes/${details?.title || details?.name}/${id}/${
        details?.number_of_seasons
      }`
    );
  };
  const castAndCrewHandler = () => {
    navigate(`/castandcrew/${type}/${id}`);
  };

  const handleCountry = (event) => {
    setCountry(event.target.value);
  };

  useEffect(() => {
    if (user && myreviews) {
      const reviews = myreviews[type === "movie" ? "movies" : "shows"];
      if (reviews && reviews[id]) {
        const includesReview = Object.values(reviews[id]).some(
          (review) => review.userId === user.uid
        );
        setReviewExist(includesReview);
      }
    }

    if (user && details?.id && !myreviews?.[id]) {
      checkIfReviewExists(id, type);
    }
    
  }, [details, id, type, myreviews, checkIfReviewExists, user]);
  console.log(details)
  useEffect(() => {
    async function fetchData() {
      if (details?.id !== id) {
        const [fetchedDetails] = await getDetails(id, type);
        setDetails(fetchedDetails);
      }
    }
    fetchData();
    setLoading(false);
  }, [id, type, country]);

  useEffect(() => {
    setLink(provider[country]?.link);
  }, [provider, country]);

  function watchChecker(watched, score) {
    setWatched(watched);
    if (watched) {
      setRatingScore(score);
    }
  }

  if (!loading) {
    return (
      <>
        {/* <TrailerBanner /> */}
        <Master>
          {" "}
          <Wrapper>
            <HeaderDetails id="headerdetails">
              <BackgroundImage bg={details?.backdrop_path}>
                <ShareComponent url={shareUrl} title={shareTitle} />
                <Standard>
                  <DetailsWrap id="detailswrap">
                    <PosterWrap>
                      {details?.tagline?.length > 3 && (
                        <Tagline leng={details?.tagline?.length}>
                          "{details?.tagline}"
                        </Tagline>
                      )}
                      <Poster bg={details?.poster_path} />
                      <Social>
                        {social.facebook_id != null && (
                          <FacebookIcon
                            onClick={() =>
                              openInNewTab(
                                `https://www.facebook.com/${social.facebook_id}`
                              )
                            }
                          />
                        )}
                        {social.instagram_id != null && (
                          <InstaIcon
                            onClick={() =>
                              openInNewTab(
                                `https://www.instagram.com/${social.instagram_id}/`
                              )
                            }
                          />
                        )}
                        {social.twitter_id != null && (
                          <TwitterIcon
                            onClick={() =>
                              openInNewTab(
                                `https://www.twitter.com/${social.twitter_id}`
                              )
                            }
                          />
                        )}
                      </Social>
                    </PosterWrap>
                    <Details id="details">
                      <UserScoreContainer>
                        <UserScore rating={details.vote_average}>
                          <h3>
                            {details?.vote_average &&
                              details?.vote_average.toFixed(1)}
                          </h3>
                        </UserScore>

                        <VotesWrapper>
                          <span>{details?.vote_count}</span>
                          <span>votes</span>
                        </VotesWrapper>
                        <FavWishAdd
                          movieId={id}
                          showOrMovie={type}
                          allDetails={details}
                          watchChecker={watchChecker}
                        />
                      </UserScoreContainer>
                      <InfoWrap>
                        <Title>{details?.title || details?.name}</Title>
                        {details?.release_date && (
                          <RelaseYear>
                            {details?.release_date.slice(0, 4)}
                          </RelaseYear>
                        )}
                        {details?.first_air_date && (
                          <RelaseYear>
                            {details?.first_air_date.slice(0, 4)}
                          </RelaseYear>
                        )}

                        <OverviewWrap>
                          <h3>Overview:</h3>
                          <ReactShowMoreText lines={3}>
                            <OverviewText>{details?.overview}</OverviewText>
                          </ReactShowMoreText>
                        </OverviewWrap>
                        <DirectorWrapper>
                          {credits != null ? (
                            <div>
                              {details?.release_date && (
                                <Producers>
                                  {" "}
                                  <h3>Director</h3>
                                  <Director
                                    onClick={() => {
                                      navigateToActorDetails(dirID);
                                    }}
                                  >
                                    {credits}
                                  </Director>
                                </Producers>
                              )}
                              {details?.first_air_date && (
                                <Producers>
                                  <h3>Producer</h3>
                                  <ProducerList>
                                    {creditsSeries?.map((producer, idx) => (
                                      <Director
                                        key={idx}
                                        onClick={() => {
                                          navigateToActorDetails(producer.id);
                                        }}
                                      >
                                        {producer.name}
                                      </Director>
                                    ))}
                                  </ProducerList>
                                </Producers>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </DirectorWrapper>
                        <CastAndCrewButton onClick={castAndCrewHandler}>
                          All cast and crew
                        </CastAndCrewButton>

                        <GenreWrap>
                          {details?.production_companies ? (
                            <>
                              <p>Production:</p>
                              {details?.production_companies.map(
                                (company, idx) => (
                                  <Company key={idx}>{company.name}</Company>
                                )
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </GenreWrap>

                        <GenreWrap>
                          <p>Genre: </p>
                          {details?.genres &&
                            details?.genres.map((genre, idx) => (
                              <Genre key={idx}> {genre.name}</Genre>
                            ))}
                        </GenreWrap>
                        <GenreWrap>
                          {details?.release_date && (
                            <>
                              <p>Initial release: </p>
                              <BoxOffice>{details?.release_date}</BoxOffice>
                            </>
                          )}
                          {details?.first_air_date && (
                            <>
                              <p>First air date: </p>
                              <BoxOffice>{details?.first_air_date}</BoxOffice>
                            </>
                          )}
                        </GenreWrap>
                        {details?.number_of_seasons > 0 ? (
                          <>
                            <p>
                              Seasons: <span>{details?.number_of_seasons}</span>
                            </p>
                            <p>
                              Episodes:{" "}
                              <span>{details?.number_of_episodes}</span>
                            </p>
                            <SeasonsDetails
                              onClick={() => {
                                navigateToSeasonsDetails(id);
                              }}
                            >
                              View all seasons and episodes
                            </SeasonsDetails>
                          </>
                        ) : (
                          <></>
                        )}
                        <ExtraInfo>
                          {details?.runtime && (
                            <Det>
                              <p>Runtime: </p>
                              <Runtime> {details?.runtime} mins</Runtime>
                            </Det>
                          )}
                          {details?.budget > 0 && (
                            <Det>
                              <p>Budget: </p>
                              <Runtime>
                                {" "}
                                {String(details?.budget).slice(0, -6)} mil
                              </Runtime>
                            </Det>
                          )}
                          {details?.revenue > 0 && (
                            <Det>
                              <p>Box Office: </p>
                              <BoxOffice>
                                {String(details?.revenue).slice(0, -6)} mil
                              </BoxOffice>
                            </Det>
                          )}
                        </ExtraInfo>
                        <JustWatchWrapper>
                          <Attribute>
                            <img
                              alt="JustWatch"
                              src="https://widget.justwatch.com/assets/JW_logo_color_10px.svg"
                            />
                          </Attribute>
                        </JustWatchWrapper>
                        <Country
                          value={country}
                          onChange={handleCountry}
                          name="country"
                          id="country"
                        >
                          <option value="GB" className="flag-icon flag-icon-gb">
                            <ReactCountryFlag countryCode="GB" />
                            United Kingdom
                          </option>
                          <option value="ES">
                            <ReactCountryFlag countryCode="ES" />
                            Spain
                          </option>
                          <option value="HU">
                            <ReactCountryFlag countryCode="HU" />
                            Hungary
                          </option>
                          <option value="IT">
                            <ReactCountryFlag
                              countryCode="IT"
                              style={{ marginRight: "5px" }}
                            />
                            Italy
                          </option>
                          <option value="US">
                            <ReactCountryFlag
                              countryCode="US"
                              style={{ marginRight: "5px" }}
                            />{" "}
                            United States
                          </option>
                        </Country>

                        {provider[country]?.flatrate ? (
                          <Providers>
                            <p>Stream:</p>
                            {provider[country].flatrate?.map(
                              (provider, idx) => (
                                <ProviderBox
                                  bg={provider?.logo_path}
                                  key={idx}
                                  onClick={() => openInNewTab(`${link}`)}
                                ></ProviderBox>
                              )
                            )}
                          </Providers>
                        ) : (
                          <></>
                        )}

                        <Providers>
                          {provider[country]?.buy ? (
                            <>
                              <p>Buy:</p>
                              {provider[country].buy?.map((provider, idx) => (
                                <ProviderBox
                                  bg={provider?.logo_path}
                                  key={idx}
                                  onClick={() => openInNewTab(`${link}`)}
                                ></ProviderBox>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </Providers>
                      </InfoWrap>
                    </Details>
                  </DetailsWrap>
                  <ReviewsSection
                    type={type}
                    id={id}
                    title={details?.title || details?.name}
                    reviews={reviews}
                    ratingScore={ratingScore}
                    watched={watched}
                  />
                </Standard>
              </BackgroundImage>
            </HeaderDetails>
            <Master>
              <Standard>
                <MovieTrailers movieid={id} screentype={type} />
                <CardHolder>
                  {cast?.length > 0 && (
                    <MasterCardWrapper
                      side="center"
                      actors="ACTORS"
                      people={cast}
                      type="person"
                    />
                  )}
                  {rekon?.length > 0 && (
                    <CategoriesWrapper
                      categ="categories"
                      side={type === "movie" ? "right" : "left"}
                      name={
                        type === "movie"
                          ? "You may like ||"
                          : " || You may like"
                      }
                      movies={rekon}
                      type={type}
                    />
                  )}
                </CardHolder>
              </Standard>
            </Master>
          </Wrapper>
        </Master>
      </>
    );
  } else {
    return <LoadingCircle />;
  }
};

export default DetailsPage;

const Master = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const Standard = styled.div`
  width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${"" /* position: relative; */}

  @media screen and (max-width: 64rem) {
    width: 100%;
    display: block;
  }
`;

const Social = styled.div`
  display: flex;
`;

const sharedStyle = css`
  display: flex;
  align-items: center;
`;

const sharedStyleP = css`
  margin: 0 20px 0 5px;
  color: white;
`;

const PosterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Tagline = styled.div`
  ${sharedStyleP}
  font-size:${(props) =>
    props.leng < "28" ? "24px" : props.leng > "50" ? "14px" : "18px"};
  padding: 0;
  margin: 0;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: ${(props) =>
      props.leng < "28" ? "24px" : props.leng > "40" ? "12px" : "18px"};
  }
`;

const Wrapper = styled.div`
  max-width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
`;

const InfoWrap = styled.div`
  width: 90%;
  height: 80%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  h3 {
    color: white;
  }

  p {
    color: #000;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0;
    width: 85%;
    align-self: center;
  }

  @media screen and (max-width: 800px) {
    margin-left: 0;
    width: 95%;
    align-self: center;
  }
`;

const OverviewWrap = styled.div`
  width: 100%;
`;

const OverviewText = styled.div`
  font-weight: normal;
`;

const HeaderDetails = styled.div`
  width: 100%;
  min-height: 700px;
  margin: 20px 0px;
  ${"" /* position:relative; */}
  display:flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 25px 0;
    justify-content: center;
    align-items: center;
  }
`;

const BackgroundImage = styled.div`
  width: 100vw;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(https://www.themoviedb.org/t/p/original${(props) => props.bg});
  background-size: cover;
  padding: 100px 0;
  margin-top: -60px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 1));
    /* url(https://www.themoviedb.org/t/p/w1280${(props) => props.bg}); */
  }
`;

const DetailsWrap = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 50px 0 50px;
  margin-top: 100px;

  @media screen and (max-width: 1025px) {
    flex-direction: column;
    padding: 0;
    margin-top: 50px;
  }
`;

const Poster = styled.div`
  width: 300px;
  height: 400px;
  border: 1px solid white;
  border-radius: 7px;
  background: url(https://www.themoviedb.org/t/p/w780${(props) => props.bg});
  background-size: cover;
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    width: 300px;
    height: 400px;
  }
`;

const ProviderBox = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin: 10px;
  background: url(https://www.themoviedb.org/t/p/original${(props) =>
    props.bg});
  background-size: cover;
  color: white;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    margin: 0 3px;
  }
`;
const Company = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 5px;
  color: white;
`;

const Details = styled.div`
  width: 90%;
  height: auto;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: hidden;

  h3 {
    color: red;
    font-size: 20px;
    margin: 10px 0;
  }

  h4,
  span {
    color: white;
    font-size: 16px;
    margin: 2px 0 10px 0;
  }

  p {
    color: red;
    font-size: 16px;
    margin: 6px 0;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    padding: 0 20px;
    margin: 10px;
  }
`;

const Producers = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const ProducerList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
`;
const Director = styled.h4`
  color: white;
  font-size: 16px;
  padding-right: 10px;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const RelaseYear = styled.div`
  color: #999;
  font-size: 24px;
  margin: 10px 0;
`;

const CastAndCrewButton = styled.div`
  background: black;
  color: #fff;
  width: 250px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 8px;
  border: 1px solid white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 3px 10px;
  font-weight: bold;

  &:hover {
    color: red;
  }
`;

const UserScoreContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 1025px) {
    justify-content: center;
    align-items: center;
  }
`;

const UserScore = styled.div`
  height: 70px;
  width: 70px;
  border: 3px solid ${(props) => (props.rating >= 6 ? "green" : "red")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  h3 {
    color: #fff;
    font-size: 30px;
  }

  @media screen and (max-width: 1025px) {
    height: 60px;
    width: 60px;

    h3 {
      font-size: 26px;
    }

    h4 {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 800px) {
    height: 40px;
    width: 40px;
    margin-right: 5px;

    h3 {
      font-size: 20px;
    }
  }
`;

const Title = styled.h1`
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0;
`;

const DirectorWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;

  div {
    display: flex;
    h3 {
      margin: 0;
      color: red;
    }

    h4 {
      margin-top: 5px;
      color: white;
      font-size: 18px;
      font-weight: normal;
    }
  }
`;

const GenreWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
  ${sharedStyle}
`;

const ExtraInfo = styled.div`
  width: 100%;
  ${sharedStyle}
`;

const Det = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const Genre = styled.div`
  ${sharedStyleP}
  margin:0 5px;
`;

const Runtime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${sharedStyleP}
`;

const BoxOffice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${sharedStyleP}
`;

const Country = styled.select`
  width: 250px;
  color: white;
  background: black;
  margin-top: 40px;
  padding: 5px;
  border-radius: 7px;
  font-size: 14px;
  border: 1.5px solid white;
`;

const Providers = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
`;

const SeasonsDetails = styled.div`
  width: 250px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px;
  padding: 3px 10px;
  font-size: 16px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid white;
  background: black;

  &:hover {
    color: red;
  }
`;
const CardHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const FacebookIcon = styled(FaFacebookSquare)`
  margin: 10px;
  cursor: pointer;
  color: #3b5998;
  background: white;
  border-radius: 6px;
  font-size: 35px;
`;

const InstaIcon = styled(FaInstagramSquare)`
  color: #d62976;
  margin: 10px;
  cursor: pointer;
  background: white;
  border-radius: 6px;
  font-size: 35px;
`;

const TwitterIcon = styled(FaTwitterSquare)`
  color: #1da1f2;
  margin: 10px;
  cursor: pointer;
  background: white;
  border-radius: 6px;
  font-size: 35px;
`;

const Attribute = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0 20px 0;

  img {
    height: 30px;
  }
`;

const VotesWrapper = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    margin: 0;
    font-size: 12px;
  }
`;
const JustWatchWrapper = styled.div`
  height: 20px;
`;
