import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import oscar from "../../assets/images/oscar.png";
import {
  MdOutlineArrowBackIosNew as BackArrow,
  MdOutlineArrowForwardIos as ForwardArrow,
} from "react-icons/md";
import {
  oscarWinnersData2023,
  oscarWinnersData2024,
  oscarWinnersData2025,
} from "../../assets/oscarwinnersData";
import OscarCard from "./OscarCard";

// Consolidate all Oscar data into a single object for dynamic access
const oscarDataByYear = {
  2023: oscarWinnersData2023,
  2024: oscarWinnersData2024,
  2025: oscarWinnersData2025,
};

const Oscar = () => {
  const [index, setIndex] = useState(0); // Index of the current winner within the selected year
  const [currentYear, setCurrentYear] = useState(2025); // Default to the latest year (2025)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  // Get available years dynamically from the data object
  const availableYears = Object.keys(oscarDataByYear).map(Number).sort((a, b) => b - a); // Sort descending
  const currentYearIndex = availableYears.indexOf(currentYear);
  const oscarData = oscarDataByYear[currentYear];
  const totalWinners = oscarData.length - 1;

  // Navigate to the previous year
  const handlePreviousYear = () => {
    const newYearIndex = currentYearIndex < availableYears.length - 1 ? currentYearIndex + 1 : 0;
    setCurrentYear(availableYears[newYearIndex]);
    setIndex(0); // Reset to first winner of the new year
  };

  // Navigate to the next year
  const handleNextYear = () => {
    const newYearIndex = currentYearIndex > 0 ? currentYearIndex - 1 : availableYears.length - 1;
    setCurrentYear(availableYears[newYearIndex]);
    setIndex(0); // Reset to first winner of the new year
  };

  return (
    <MainWrap id="oscar">
      <TitleWrap>
        <YearToggle>
          {currentYearIndex < availableYears.length - 1 && (
            <SmallLeftArrow onClick={handlePreviousYear} />
          )}
        </YearToggle>

        <OscarLogo />
        <OscarTitle>OSCAR WINNERS {currentYear}</OscarTitle>

        <YearToggle>
          {currentYearIndex > 0 && <SmallRightArrow onClick={handleNextYear} />}
        </YearToggle>
      </TitleWrap>
      <Box>
        <LeftArrow
          onClick={() => setIndex(index !== 0 ? index - 1 : totalWinners)}
        />
        <OscarCard
          data={oscarData[index]}
          type="movie"
          videoRef={videoRef}
          playing={isVideoPlaying}
          onPlay={() => setIsVideoPlaying(true)}
          onPause={() => setIsVideoPlaying(false)}
        />
        <RightArrow
          onClick={() => setIndex(index !== totalWinners ? index + 1 : 0)}
        />
      </Box>
    </MainWrap>
  );
};

export default Oscar;

// Styled components remain largely unchanged, only minor adjustments for clarity
const MainWrap = styled.div`
  width: 98%;
  height: auto;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px 0;
  padding: 5px 0;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 30px 0;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const OscarLogo = styled.div`
  width: 15px;
  height: 40px;
  background: url(${oscar});
  background-size: cover;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    width: 10昔

    height: 26px;
  }
`;

const OscarTitle = styled.div`
  font-size: 24px;
  color: gold;
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin: 10px 0;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 15px 15px 0;
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 60%;
    margin: 0;
  }
`;

const ArrowStyle = css`
  color: #fff;
  font-size: 3vw;
  z-index: 500;
  top: 50%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    font-size: 10vw;
    margin-left: 0;
  }
`;

const LeftArrow = styled(BackArrow)`
  ${ArrowStyle};
`;

const RightArrow = styled(ForwardArrow)`
  ${ArrowStyle};
`;

const SmallLeftArrow = styled(BackArrow)`
  cursor: pointer;
  color: #fff;
  font-size: 20px;
`;

const SmallRightArrow = styled(ForwardArrow)`
  cursor: pointer;
  color: #fff;
  font-size: 20px;
`;

const YearToggle = styled.div`
  color: #fff;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;