import React, { useContext } from "react";
import styled from "styled-components";
import NoImage from "../assets/images/noImage.png";
import TmdbContext from "../context/TmdbContext";
import HeaderBackdrop from "../components/HeaderBackdrop/HeaderBackdropcomponent";
import { useNavigate, useParams } from "react-router-dom";
const Crew = () => {
  const departments = {};
  const { cast, crew, details } = useContext(TmdbContext);
  const navigate = useNavigate();

  const { id, type } = useParams();
  // Navigate to ActorDetails Page
  const crewHandler = (crewid) => {
    navigate(`/actordetails/${crewid}`);
  };
  // Navigate to Details Page
  const goToMoviePage = () => {
    navigate(`../detailspage/${type}/${id}`);
  };
  // Group crew members by department
  crew.forEach((member) => {
    if (member.department in departments) {
      departments[member.department].push(member);
    } else {
      departments[member.department] = [member];
    }
  });

  // Filter the director and writing crew members
  const director = crew.filter((member) => member.job === "Director");
  const writers = crew.filter((member) => member.department === "Writing");

  return (
    <>
      <Master>
        <HeaderBackdrop />
        <Standard>
          <Container>
            <Wrapper>
              <HeaderWrapper>
                <Poster bg={details.poster_path} />
                <div>
                  <h3 onClick={goToMoviePage}>
                    {details?.title || details?.name}
                  </h3>
                  <Heading>Full Cast & Crew</Heading>
                </div>
              </HeaderWrapper>

              {/* Director */}
              {director?.length > 0 && (
                <Section>
                  <SectionHeading>Directed by</SectionHeading>
                  <List>
                    {director?.map((member, idx) => (
                      <ListItem key={idx}>
                        <Image
                          src={
                            member.profile_path
                              ? `https://www.themoviedb.org/t/p/original${member.profile_path}`
                              : NoImage
                          }
                          alt={member.name}
                        />
                        <Name
                          onClick={() => {
                            crewHandler(member.id);
                          }}
                        >
                          {member.name}
                        </Name>
                      </ListItem>
                    ))}
                  </List>
                </Section>
              )}

              {/* Writing */}
              {writers?.length > 0 && (
                <Section>
                  <SectionHeading>Writing Credits</SectionHeading>
                  <List>
                    {writers.map((member, idx) => (
                      <ListItem key={idx}>
                        <Image
                          src={
                            member.profile_path
                              ? `https://www.themoviedb.org/t/p/original${member.profile_path}`
                              : NoImage
                          }
                          alt={member.name}
                        />
                        <Name
                          onClick={() => {
                            crewHandler(member.id);
                          }}
                        >
                          {member.name}
                        </Name>
                      </ListItem>
                    ))}
                  </List>
                </Section>
              )}

              {/* Cast & Crew */}
              <Section>
                <SectionHeading>Cast & Crew</SectionHeading>
                <List>
                  {cast?.map((member, idx) => (
                    <ListItem key={idx}>
                      <Image
                        src={
                          member.profile_path
                            ? `https://www.themoviedb.org/t/p/original${member.profile_path}`
                            : NoImage
                        }
                        alt={member.name}
                      />
                      <Name
                        onClick={() => {
                          crewHandler(member.id);
                        }}
                      >
                        {member.name}
                      </Name>
                      <Character>{member.character}</Character>
                    </ListItem>
                  ))}
                  {Object.entries(departments).map(([department, members]) => (
                    <Department key={department}>
                      <DepartmentHeading>{department}</DepartmentHeading>
                      <DepartmentList>
                        {members?.map((member, idx) => (
                          <ListItem key={idx}>
                            {/* <Image src={`https://www.themoviedb.org/t/p/original${member.profile_path}`} alt={member.name} /> */}
                            <Name
                              onClick={() => {
                                crewHandler(member.id);
                              }}
                            >
                              {member.name}
                            </Name>
                            <Character>{member.job}</Character>
                          </ListItem>
                        ))}
                      </DepartmentList>
                    </Department>
                  ))}
                </List>
              </Section>
            </Wrapper>
          </Container>
        </Standard>
      </Master>
    </>
  );
};

export default Crew;

const Master = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Standard = styled.div`
  width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background:#EDEEF2; */

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border: 1px solid #000;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 7px
  );
  box-shadow: 3px 3px 3px #444;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
const Wrapper = styled.div`
  width: 90%;
  height: 100%;
  padding: 10px;
  border: 0.5px solid #888;
  border-radius: 8px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  div {
    h3 {
      color: #136cb2;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Section = styled.section`
  margin-bottom: 20px;
`;

const SectionHeading = styled.h2`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;

  &:after {
    margin-top: -0.6em;

    content: "";
    border-bottom: 1px dotted #ccc;
    width: 90%;
    float: left;
  }

  /* border-bottom: 1px dotted #888; */
`;
const Poster = styled.div`
  width: 100px;
  height: 150px;
  border: 1px solid white;
  border-radius: 7px;
  background: url(https://www.themoviedb.org/t/p/original${(props) =>
    props.bg});
  background-size: cover;
  margin: 10px;
  @media screen and (max-width: 768px) {
    width: 300px;
    height: 400px;
  }
`;
const Heading = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Department = styled.div`
  flex-basis: 100%;
  margin-top: 20px;
  &:before {
    margin-top: -0.6em;

    content: "";
    border-bottom: 1px dotted #ccc;
    width: 90%;
    float: left;
  }
`;

const DepartmentHeading = styled.h3`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const DepartmentList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  /* justify-content:center; */
  flex: 0 0 33.33%;
  margin-bottom: 20px;
  padding: 0 10px;

  @media (max-width: 767px) {
    flex: 0 0 50%;
  }

  @media (max-width: 479px) {
    flex: 0 0 100%;
  }
`;

const Image = styled.img`
  display: block;
  margin-bottom: 10px;
  width: 30px;
  margin-right: 10px;
`;

const Name = styled.span`
  display: block;
  font-size: 13px;
  min-width: 150px;
  font-weight: bold;
  margin-right: 50px;
  color: #136cb2;
  cursor: pointer;
`;

const Character = styled.span`
  display: block;
  font-size: 13px;
`;
