export const oscarWinnersData2023 = [
  {
    backdrop_path: "/ss0Os3uWJfQAENILHZUdX8Tt1OC.jpg",
    trailer: "DZldmL7zeSY",
    release_date: "2022-03-24",
    id: 545611,
    original_title: "Everything Everywhere All at Once",
    poster_path: "/nrbHNzSMydpWK9um5VqWIFJihB5.jpg",
    title: "Everything Everywhere All at Once",
    vote_average: 7.842,
    vote_count: 4688,
    award: "Best Actress",
    name: "Michelle Yeoh",
    type: "person",
    personId: "1620",
  },
  {
    backdrop_path: "/7hj8WPqggzqRfs56oe0WAPg0MaB.jpg",
    trailer: "PD2w1NNoNE4",
    id: 785084,
    original_title: "The Whale",
    poster_path: "/tFj5PaWWQbb8rHBBhu1EHklznph.jpg",
    release_date: "2022-12-09",
    title: "The Whale",
    vote_average: 8.039,
    vote_count: 2355,
    award: "Best Actor",
    name: "Brendan Fraser",
    type: "person",
    personId: "18269",
  },
  {
    backdrop_path: "/fIwiFha3WPu5nHkBeMQ4GzEk0Hv.jpg",
    trailer: "wxN1T1uxQ2g",
    release_date: "2022-03-24",
    id: "545611",
    original_title: "Everything Everywhere All at Once",
    poster_path: "/w3LxiVYdWWRvEVdn5RYq6jIqkb1.jpg",
    title: "Everything Everywhere All at Once",
    vote_average: "7.8",
    vote_count: "5842",
    award: "Best Picture | Best Original Screenplay",
    type: "movie",
  },
  {
    backdrop_path: "/fIwiFha3WPu5nHkBeMQ4GzEk0Hv.jpg",
    trailer: "CprWYYueiw4",
    release_date: "2022-03-24",
    id: "545611",
    original_title: "Everything Everywhere All at Once",
    poster_path: "/5VSpMLDEmygCu4bfB89Y7NV6YjM.jpg",
    title: "Everything Everywhere All at Once",
    vote_average: "7.8",
    vote_count: "5842",
    award: "Best Director",
    type: "person",
    personId: "1317730",
    name: "Daniel Scheinert",
  },
  {
    backdrop_path: "/mqsPyyeDCBAghXyjbw4TfEYwljw.jpg",
    trailer: "hf8EYbVxtCY",
    original_title: "All Quiet on the Western Front",
    poster_path: "/2IRjbi9cADuDMKmHdLK7LaqQDKA.jpg",
    release_date: "2022-10-07",
    title: "All Quiet on the Western Front",
    vote_average: 7.754,
    vote_count: 2728,
    award: "Best International Film | Cinematography | Production Design",
    type: "movie",
  },
  {
    backdrop_path: "/fIwiFha3WPu5nHkBeMQ4GzEk0Hv.jpg",
    trailer: "EZ1SUKxSAi4",
    release_date: "2022-03-24",
    id: "545611",
    original_title: "Everything Everywhere All at Once",
    poster_path: "/5PfYVcNLs1gGKIo0qwJrvyc2UOZ.jpg",
    title: "Everything Everywhere All at Once",
    vote_average: "7.8",
    vote_count: "5842",
    award: "Best Supporting Actor",
    name: "Ke Huy Kwan",
    type: "person",
    personId: "690",
  },
  {
    backdrop_path: "/fIwiFha3WPu5nHkBeMQ4GzEk0Hv.jpg",
    trailer: "dOfMbZLbBDA",
    release_date: "2022-03-24",
    id: "545611",
    original_title: "Everything Everywhere All at Once",
    poster_path: "/9KWvPVeiLOXlOGl0XVyHZtJWQtx.jpg",
    title: "Everything Everywhere All at Once",
    vote_average: "7.8",
    vote_count: "5842",
    award: "Best Supporting Actress",
    name: "Jamie Lee Curtis",
    type: "person",
    personId: "690",
  },
  {
    backdrop_path: "/e782pDRAlu4BG0ahd777n8zfPzZ.jpg",
    trailer: "Od2NW1sfRdA",
    id: 555604,
    original_title: "Guillermo del Toro's Pinocchio",
    poster_path: "/vx1u0uwxdlhV2MUzj4VlcMB0N6m.jpg",
    release_date: "2022-11-09",
    title: "Guillermo del Toro's Pinocchio",
    vote_average: 8.2,
    vote_count: 2273,
    award: "Best Animated Feature",
    type: "movie",
  },
  {
    backdrop_path: "/hUtrCoGrlo3o8juAI1ZzA7F1LX9.jpg",
    trailer: "dH7Sl2h_aHs",
    id: 777245,
    original_title: "Women Talking",
    poster_path: "/twUbb6Irktv0VEsJXQWJ3VKyxFX.jpg",
    release_date: "2022-12-23",
    title: "Women Talking",
    vote_average: 7.027,
    vote_count: 313,
    award: "Best Adapted Screenplay",
    type: "movie",
  },
  {
    backdrop_path: "/suw8eyL3YwE4wfzPQ0cLR02k0Gh.jpg",
    trailer: "o5F8MOz_IDw",
    id: 76600,
    original_title: "Avatar: The Way of Water",
    poster_path: "/t6HIqrRAclMCA60NsSmeqe9RmNV.jpg",
    release_date: "2022-12-14",
    title: "Avatar: The Way of Water",
    award: "Best Visual Effects",
    type: "movie",
  },
];

export const bestActorData2023 = [
  {
    profile_path: "/tFj5PaWWQbb8rHBBhu1EHklznph.jpg",
    name: "Brendan Fraser",
    actor_id: 18269,
    backdrop_path: "/7hj8WPqggzqRfs56oe0WAPg0MaB.jpg",
    id: 785084,
    original_title: "The Whale",
    poster_path: "/jQ0gylJMxWSL490sy0RrPj1Lj7e.jpg",
    release_date: "2022-12-09",
    title: "The Whale",
    vote_average: 8.039,
    vote_count: 2355,
    award: "Best Actor",
  },
];

export const oscarWinnersData2024 = [
  {
    backdrop_path: "/ycnO0cjsAROSGJKuMODgRtWsHQw.jpg",
    trailer: "Ila2XMCBF3Q",
    release_date: "2023-07-19",
    id: "872585",
    original_title: "Oppenheimer",
    poster_path: "/2lKs67r7FI4bPu0AXxMUJZxmUXn.jpg",
    title: "Oppenheimer",
    vote_average: "8.114",
    vote_count: "7032",
    award: "Best Actor",
    name: "Cillian Murphy",
    type: "person",
    personId: "2037",
  },
  {
    backdrop_path: "/bQS43HSLZzMjZkcHJz4fGc7fNdz.jpg",
    trailer: "XDYBUnCqsyw",
    release_date: "2023-12-07",
    id: "792307",
    original_title: "Poor Things",
    poster_path: "/cZ8a3QvAnj2cgcgVL6g4XaqPzpL.jpg",
    title: "Poor Things",
    vote_average: "7.919",
    vote_count: "2007",
    award: "Best Actress",
    name: "Emma Stone",
    type: "person",
    personId: "54693",
  },
  {
    backdrop_path: "/ycnO0cjsAROSGJKuMODgRtWsHQw.jpg",
    trailer: "BG6ed8Qexbs",
    release_date: "2023-07-19",
    id: "872585",
    original_title: "Oppenheimer",
    poster_path: "/xuAIuYSmsUzKlUMBFGVZaWsY3DZ.jpg",
    title: "Oppenheimer",
    vote_average: "8.114",
    vote_count: "7032",
    award: "Best Director",
    name: "Christopher Nolan",
    type: "person",
    personId: "525",
  },
  {
    backdrop_path: "/ycnO0cjsAROSGJKuMODgRtWsHQw.jpg",
    trailer: "zGirQuMmNGc",
    release_date: "2023-07-19",
    id: "872585",
    original_title: "Oppenheimer",
    poster_path: "/8Gxv8gSFCU0XGDykEGv7zR1n2ua.jpg",
    title: "Oppenheimer",
    vote_average: "8.114",
    vote_count: "7032",
    award: "Best Picture",
    name: "",
    type: "movie",
  },
  {
    backdrop_path: "/3mpgltEMgPf8zFtPnAWdDVN8ZT1.jpg",
    trailer: "d07lNlkn0Ug",
    release_date: "2023-11-10",
    id: "1056360",
    original_title: "American Fiction",
    poster_path: "/57MFWGHarg9jid7yfDTka4RmcMU.jpg",
    title: "American Fiction",
    vote_average: "7.313",
    vote_count: "470",
    award: "Best Adapted Screenplay",
    type: "movie",
  },
  {
    backdrop_path: "/bWIIWhnaoWx3FTVXv6GkYDv3djL.jpg",
    trailer: "VvSrHIX5a-0",
    release_date: "2023-11-03",
    id: "940721",
    original_title: "Godzilla Minus One",
    poster_path: "/hkxxMIGaiCTmrEArK7J56JTKUlB.jpg",
    title: "Godzilla Minus One",
    vote_average: "7.9",
    vote_count: "435",
    award: "Best Visual Effects",
    type: "movie",
  },
  {
    backdrop_path: "/ycnO0cjsAROSGJKuMODgRtWsHQw.jpg",
    trailer: "oH4tQzxcpPI",
    release_date: "2023-07-19",
    id: "872585",
    original_title: "Oppenheimer",
    poster_path: "/im9SAqJPZKEbVZGmjXuLI4O7RvM.jpg",
    title: "Oppenheimer",
    vote_average: "8.114",
    vote_count: "7032",
    award: "Best Supporting Actor",
    name: "Robert Downey Jr.",
    type: "person",
    personId: "3223",
  },
  {
    backdrop_path: "/57fhbxl9is7W7ur5v6UuvlNsoSg.jpg",
    trailer: "jgTEY9rb2EU",
    release_date: "2023-10-27",
    id: "840430",
    original_title: "The Holdovers",
    poster_path: "/awNT6lltD8zItbGtolmO8IGT8ot.jpg",
    title: "The Holdovers",
    vote_average: "7.77",
    vote_count: "948",
    award: "Best Supporting Actress",
    name: "Da'Vine Joy Randolph",
    type: "person",
    personId: "1180099",
  },
  {
    backdrop_path: "/midCh3iVxaG3dUOrP9TnUpNcGm.jpg",
    trailer: "t5khm-VjEu4",
    release_date: "2023-07-14",
    id: "508883",
    original_title: "The Boy and the Heron",
    poster_path: "/jDQPkgzerGophKRRn7MKm071vCU.jpg",
    title: "The Boy and the Heron",
    vote_average: "7.472",
    vote_count: "1034",
    award: "Best Animated Feature",
    name: "Animation",
    type: "movie",
  },
];

// Add this to details page to get easy data for the movie:

{
  /* <div style={{ color: "yellow" }}>
                        <h3>{`backdrop_path:"${details.backdrop_path}",`}</h3>
                        <h3>{`trailer:"",`}</h3>
                        <h3>{`release_date:"${details.release_date}",`}</h3>
                        <h3>{`id:"${details.id}",`}</h3>
                        <h3>{`original_title:"${details.original_title}",`}</h3>
                        <h3>{`poster_path:"${details.poster_path}",`}</h3>
                        <h3>{`title:"${details.title}",`}</h3>
                        <h3>{`vote_average:"${details.vote_average}",`}</h3>
                        <h3>{`vote_count:"${details.vote_count}",`}</h3>
                        <h3>{`award:"",`}</h3>
                        <h3>{`name:"",`}</h3>
                      </div> */
}
export const oscarWinnersData2025 = [
  // Best Picture (previously filled with "Anora" data)
  {
    trailer:'z20wJkdKz98',
    backdrop_path: "/kEYWal656zP5Q2Tohm91aw6orlT.jpg",
    release_date: "2024-10-14",
    original_title: "Anora",
    poster_path: "/43bJoLoMJnc68Fua1Oa6wrqfeIG.jpg",
    title: "Anora",
    vote_average: "7.1",
    vote_count: "1230",
    award: "Best Picture",
    type: "movie",
    id:'1064213'
  },

  // Best Actor (previously filled with Adrien Brody for "The Brutalist")
  {
    trailer:'m9fwno4Rsk4',
    backdrop_path: "/iKXXPYBWQ0B6BMRHAFbg3wKafWb.jpg",
    release_date: "2024-12-20",
    original_title: "The Brutalist",
    poster_path: "/qBc7ahQrpVpcllaZ5hkivsOEb3C.jpg",
    title: "The Brutalist",
    vote_average: "7.1",
    vote_count: "668",
    award: "Best Actor",
    name: "Adrien Brody",
    type: "person",
    personId: "3490",
  },

  // Best Actress (previously filled with Mikey Madison for "Anora")
  {
    trailer:'Ni44FwTgvzo',
    backdrop_path: "/kEYWal656zP5Q2Tohm91aw6orlT.jpg",
    release_date: "2024-10-14",
    original_title: "Anora",
    poster_path: "/b0HZr4Xa4pIR7MzTPXfIWvUZELx.jpg",
    title: "Anora",
    vote_average: "7.1",
    vote_count: "1230",
    award: "Best Actress",
    name: "Mikey Madison",
    type: "person",
    personId: "1640439",
  },

  // Best Director (previously filled with Sean Baker for "Anora")
  {
    trailer:'JVxVptGNZAA',
    backdrop_path: "/kEYWal656zP5Q2Tohm91aw6orlT.jpg",
    release_date: "2024-10-14",
    original_title: "Anora",
    poster_path: "/wKfNgfRvPhLHMrwuweSwLOmhRzT.jpg",
    title: "Anora",
    vote_average: "7.1",
    vote_count: "1230",
    award: "Best Director",
    name: "Sean Baker",
    type: "person",
    personId: "118415",
  },

  // Best Supporting Actor (previously filled with Kieran Culkin for "A Real Pain")
  {
    trailer:'c_sV4lkZbnc',
    backdrop_path: "/nGC3ZkXEcjGbqAAonS1xn6gaNpb.jpg",
    release_date: "2024-11-01",
    original_title: "A Real Pain",
    poster_path: "/b5EC4nziLhBRX4GOcYx2BdS3FTt.jpg",
    title: "A Real Pain",
    vote_average: "6.8",
    vote_count: "575",
    award: "Best Supporting Actor",
    name: "Kieran Culkin",
    type: "person",
    personId: "18793",
  },

  // Best Supporting Actress (previously filled with Zoe Saldaña for "Emilia Pérez")
  {
    trailer:'HLaXEgzFucM',
    backdrop_path: "/u2eA9pqi1q3DvevT7RuDuJHxxBT.jpg",
    release_date: "2024-08-21",
    original_title: "Emilia Pérez",
    poster_path: "/snQ1rfO9Bb2LRG9MAOQnn3JXVHy.jpg",
    title: "Emilia Pérez",
    vote_average: "6.864",
    vote_count: "1371",
    award: "Best Supporting Actress",
    name: "Zoe Saldaña",
    type: "person",
    personId: "8691",
  },

  // Best Adapted Screenplay (filled with "Conclave" data)
  {
    trailer:'t915aZmyEBg',
    backdrop_path: "/tkRDTu9hyWgaBSSzfkYDCZYd1kV.jpg",
    release_date: "2024-10-25",
    original_title: "Conclave",
    poster_path: "/m5x8D0bZ3eKqIVWZ5y7TnZ2oTVg.jpg",
    title: "Conclave",
    vote_average: "7.1",
    vote_count: "1478",
    award: "Best Adapted Screenplay",
    type: "movie",
    id:'974576'
  },

  // Best Original Screenplay (filled with "Anora" data, confirming prior entry)
  {
    trailer:'08sR1MY2wpU',
    backdrop_path: "/kEYWal656zP5Q2Tohm91aw6orlT.jpg",
    release_date: "2024-10-14",
    original_title: "Anora",
    poster_path: "/43bJoLoMJnc68Fua1Oa6wrqfeIG.jpg",
    title: "Anora",
    vote_average: "7.1",
    vote_count: "1230",
    award: "Best Original Screenplay",
    type: "movie",
    id:'1064213'
  },

  // Best Animated Feature (filled with "Flow" data)
  {
    trailer:'ZgZccxuj2RY',
    backdrop_path: "/b3mdmjYTEL70j7nuXATUAD9qgu4.jpg",
    release_date: "2024-08-29",
    original_title: "Straume",
    poster_path: "/imKSymKBK7o73sajciEmndJoVkR.jpg",
    title: "Flow",
    vote_average: "8.3",
    vote_count: "1254",
    award: "Best Animated Feature",
    type: "movie",
    id:'823219'
  },

  // Best Visual Effects (filled with "Dune: Part Two" data)
  {
    trailer:'U2Qp5pL3ovA',
    backdrop_path: "/xOMo8BRK7PfcJv9JCnx7s5hj0PX.jpg",
    release_date: "2024-02-27",
    original_title: "Dune: Part Two",
    poster_path: "/6izwz7rsy95ARzTR3poZ8H6c5pp.jpg",
    title: "Dune: Part Two",
    vote_average: "8.1",
    vote_count: "6339",
    award: "Best Visual Effects",
    type: "movie",
    id:'693134'
  },

  // Best International Film (filled with "I'm Still Here" data)
  {
    trailer:'gDunV808Yf4',
    backdrop_path: "/j9uruwRe9qM8RnP758dF7ISB8Bj.jpg",
    release_date: "2024-09-19",
    original_title: "Ainda Estou Aqui",
    poster_path: "/qNLMPY3KLrYgTX2QZ5iEwwOqyRz.jpg",
    title: "I'm Still Here",
    vote_average: "7.9",
    vote_count: "390",
    award: "Best International Film",
    type: "movie",
    id:'1000837'
  },

  // Best Cinematography (filled with "The Brutalist" data)
  {
    trailer:'GdRXPAHIEW4',
    backdrop_path: "/iKXXPYBWQ0B6BMRHAFbg3wKafWb.jpg",
    release_date: "2024-12-20",
    original_title: "The Brutalist",
    poster_path: "/fCCXBtsV3HJm4PD3fUsaPngJuLm.jpg",
    title: "The Brutalist",
    vote_average: "7.1",
    vote_count: "668",
    award: "Best Cinematography",
    type: "movie",
    id:'549509'
  },

  // Best Production Design (filled with "Wicked" data)
  {
    trailer:'pqi45Qhq3CI',
    backdrop_path: "/uKb22E0nlzr914bA9KyA5CVCOlV.jpg",
    release_date: "2024-11-20",
    original_title: "Wicked",
    poster_path: "/xDGbZ0JJ3mYaGKy4Nzd9Kph6M9L.jpg",
    title: "Wicked",
    vote_average: "6.896",
    vote_count: "1847",
    award: "Best Production Design",
    type: "movie",
    id:'402431'
  },

  // Best Costume Design (filled with "Wicked" data)
  {
    trailer:'6COmYeLsz4c',
    backdrop_path: "/uKb22E0nlzr914bA9KyA5CVCOlV.jpg",
    release_date: "2024-11-20",
    original_title: "Wicked",
    poster_path: "/xDGbZ0JJ3mYaGKy4Nzd9Kph6M9L.jpg",
    title: "Wicked",
    vote_average: "6.896",
    vote_count: "1847",
    award: "Best Costume Design",
    type: "movie",
    id:'402431'
  },

  // Best Sound (filled with "Dune: Part Two" data)
  {
    trailer:'ABfQGtUEEzA',
    backdrop_path: "/xOMo8BRK7PfcJv9JCnx7s5hj0PX.jpg",
    release_date: "2024-02-27",
    original_title: "Dune: Part Two",
    poster_path: "/6izwz7rsy95ARzTR3poZ8H6c5pp.jpg",
    title: "Dune: Part Two",
    vote_average: "8.1",
    vote_count: "6339",
    award: "Best Sound",
    type: "movie",
    id:'693134'
  },

  // Best Original Score (filled with "The Brutalist" data)
  {
    trailer:'6d7yU379Ur0',
    backdrop_path: "/iKXXPYBWQ0B6BMRHAFbg3wKafWb.jpg",
    release_date: "2024-12-20",
    original_title: "The Brutalist",
    poster_path: "/fCCXBtsV3HJm4PD3fUsaPngJuLm.jpg",
    title: "The Brutalist",
    vote_average: "7.1",
    vote_count: "668",
    award: "Best Original Score",
    type: "movie",
    id:'549509'
  },
];

// Optional: Best Actor Data Template (if needed separately, as in bestActorData2023)
export const bestActorData2025 = [
  {
    profile_path: "",
    name: "",
    actor_id: "",
    backdrop_path: "",
    original_title: "",
    poster_path: "",
    release_date: "",
    title: "",
    vote_average: "",
    vote_count: "",
    award: "Best Actor",
  },
];